import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";
import { useFileStore } from "../../../strore/useFileStore";

const FiltersPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.filtersPhp);
    const { advancedItem } = useGlobalPluginInfo();

    const { baseNamespace } = advancedItem;

    useEffect(() => {
        let value = `<?php

namespace ${baseNamespace}\\Pro;

// if direct access than exit the file.
defined('ABSPATH') || exit;

class Filters
{
    public function __construct()
    {

    }
}`;
        setStore({ filtersPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseNamespace]);

    return null;
};

export default FiltersPhp;
