import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../../context/pluginInfoContext";
import { useFileStore } from "../../../strore/useFileStore";

const LibManagerPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.libManagerPhp);
    const { pluginInfo, advancedItem, mainMenuItem, license } = useGlobalPluginInfo();

    const { pluginName, pluginTextDomain, pluginAuthor, pluginAuthorEmail } = pluginInfo;
    const { constantPrefix, functionPrefix, mainClassName, baseNamespace } = advancedItem;
    const { menuPageSlug } = mainMenuItem;

    useEffect(() => {
        let value = `<?php

if ( ! function_exists( '${functionPrefix}_license_client' ) ) {
	/**
	 * License Client function
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	function ${functionPrefix}_license_client() {
		global $${functionPrefix}_license_client;

		if ( ! isset( $${functionPrefix}_license_client ) ) {
			// Include SDK.
			require_once ${constantPrefix}_PRO_DIR . 'Pro/License/Loader.php';

			$${functionPrefix}_license_client = new \\${baseNamespace}\\Pro\\Loader(
				array(
					'plugin_root'      => ${constantPrefix}_PRO_FILE,
					'software_version' => ${constantPrefix}_PRO_VER,
					'software_title'   => ${constantPrefix}_PRO,
					'product_id'       => ${license?.productId ? license?.productId : `''`},
					'redirect_url'     => ${
                        license?.redirectUrl
                            ? `'${license?.redirectUrl}'`
                            : `admin_url( 'admin.php?page=' . ${constantPrefix}_PRO_SLUG . '-license-activation' )`
                    },
					'software_type'    => '${license?.softwareType}', // theme/plugin .
					'api_end_point'    => \\${baseNamespace}\\Libs\\Helper::api_endpoint(),
					'text_domain'      => '${pluginTextDomain}',
					'license_menu'     => array(
						'icon_url'    => '${
                            license?.menu?.iconUrl
                                ? `${license?.menu?.iconUrl}`
                                : "dashicons-image-filter"
                        }',
						'position'    => ${license?.menu?.position ? license?.menu?.position : 40},
						'menu_type'   => '${
                            license?.menu?.type ? `${license?.menu?.type}` : "add_submenu_page"
                        }', // 'add_submenu_page',${
            license?.menu?.type === "add_submenu_page"
                ? `\n ${" ".repeat(23)}'parent_slug' => '${
                      license?.menu?.parentSlug
                          ? `${license?.menu?.parentSlug}`
                          : `${menuPageSlug}-settings`
                  }',`
                : ""
        }
						'menu_title'  => esc_html__( '${
                            license?.menu?.menuTitle ? `${license?.menu?.menuTitle}` : "License"
                        }', '${pluginTextDomain}' ),
						'page_title'  => esc_html__( '${
                            license?.menu?.pageTitle
                                ? `${license?.menu?.pageTitle}`
                                : `${pluginName} License Activation`
                        }', '${pluginTextDomain}' ),
					),
				)
			);
		}

		return $${functionPrefix}_license_client;
	}

	// Init ${mainClassName}_Wc_Client.
	${functionPrefix}_license_client();

	// Signal that ${mainClassName}_Wc_Client was initiated.
	do_action( '${functionPrefix}_license_client_loaded' );
}`;
        setStore({ libManagerPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        constantPrefix,
        pluginAuthor,
        pluginAuthorEmail,
        functionPrefix,
        license,
        mainClassName,
        baseNamespace,
        menuPageSlug,
        pluginName,
        pluginTextDomain,
    ]);
    return null;
};

export default LibManagerPhp;
