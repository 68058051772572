import React, { useState, Fragment, useEffect } from "react";
import ViewTree from "../components/ViewTree/ViewTree";
import { useGlobalPluginInfo } from "../context/pluginInfoContext";
import { FileRenderer } from "./FileRenderer";
import { getPluginFolderName } from "../utils/getPluginFolderName";
import { useFileStore } from "../strore/useFileStore";
import { getDownload } from "./download";

import menuIcon from "../Codebase/assets/images/menu-icon.png";
import promoImage from "../Codebase/assets/images/promo-image.png";

const GeneratedFiles = () => {
    const [files] = useFileStore((store) => store);

    // pluginInfo useContext
    const { pluginInfo, addCssItem, addJsItem, pluginType, mainMenuItem } = useGlobalPluginInfo();
    const { isAdminSettings } = mainMenuItem;

    // Toggole States
    const [pluginNameToggole, setPluginNameToggole] = useState(false);

    //file structure state
    const [fileStructures, setFileStructures] = useState([]);

    const rootFolderName = getPluginFolderName(pluginInfo.pluginName);

    useEffect(() => {
        // assets css
        let dynamicCss = addCssItem?.map((item) => {
            return {
                type: "file",
                icon: "fa-brands fa-css3-alt",
                name: `${item?.fileName}`,
            };
        });
        // dev scss
        let dynamicScss = addCssItem?.map((item) => {
            return {
                type: "file",
                icon: "fa-brands fa-sass",
                name: `${item?.handleName}.scss`,
            };
        });

        //assets js
        let dynamicJs = addJsItem?.map((item) => {
            return {
                type: "file",
                icon: "fa-brands fa-square-js",
                name: `${item?.fileName}`,
            };
        });

        //dev js
        let dynamicJsHandle = addJsItem?.map((item) => {
            return {
                type: "file",
                icon: "fa-brands fa-square-js",
                name: `${item?.handleName}.js`,
            };
        });

        //main
        let mainData = [
            {
                type: "folder",
                folderName: ".wordpress-org",
                path: ".wordpress-org",
                isExpand: true,
                files: [],
                folders: [],
            },
            {
                type: "folder",
                folderName: ".github",
                path: ".github",
                isExpand: true,
                files: [],
                folders: [
                    {
                        type: "folder",
                        folderName: "workflows",
                        path: ".github/workflows",
                        isExpand: true,
                        files: [
                            {
                                type: "file",
                                icon: "fa-brands fa-git-alt",
                                name: "build-release-zip.yml",
                                data: files.buildReleaseZip,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-git-alt",
                                name: "dependency-review.yml",
                                data: files.dependencyReview,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-git-alt",
                                name: "php-compatibility.yml",
                                data: files.phpCompatibility,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-git-alt",
                                name: "phpcs.yml",
                                data: files.phpcs,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-git-alt",
                                name: "phpunit.yml",
                                data: files.phpunit,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-git-alt",
                                name: "push-deploy.yml",
                                data: files.pushDeploy,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-git-alt",
                                name: "wordpress-latest.yml",
                                data: files.wordpressLatest,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-git-alt",
                                name: "wpcs.yml",
                                data: files.wpcs,
                            },
                        ],
                        folders: [],
                    },
                ],
            },
            {
                type: "folder",
                folderName: ".vscode",
                path: ".vscode",
                isExpand: true,
                files: [
                    {
                        type: "file",
                        icon: "fa-brands fa-git-alt",
                        name: "settings.json",
                        data: files.settingsJson,
                    },
                ],
                folders: [],
            },
            {
                type: "folder",
                folderName: "assets",
                path: "assets",
                isExpand: true,
                files: [],
                folders: [
                    {
                        type: "folder",
                        folderName: "css",
                        path: "assets/css",
                        isExpand: true,
                        files: [
                            {
                                type: "file",
                                icon: "fa-brands fa-css3-alt",
                                name: `${rootFolderName}-admin.css`,
                                data: files.pluginNameAdminCss,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-css3-alt",
                                name: `${rootFolderName}-frontend.css`,
                                data: files.pluginNameFrontendCss,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-css3-alt",
                                name: `${rootFolderName}-survey.css`,
                                data: files.pluginSurveyCss,
                            },
                            ...dynamicCss,
                        ],
                        folders: [],
                    },
                    {
                        type: "folder",
                        folderName: "images",
                        path: "assets/images",
                        isExpand: true,
                        files: [
                            {
                                type: "file",
                                img: true,
                                icon: "fa-regular fa-image",
                                name: "menu-icon.png",
                                data: menuIcon,
                            },
                            {
                                type: "file",
                                img: true,
                                icon: "fa-regular fa-image",
                                name: "promo-image.png",
                                data: promoImage,
                            },
                        ],
                        folders: [],
                    },
                    {
                        type: "folder",
                        folderName: "js",
                        path: "assets/js",
                        isExpand: true,
                        files: [
                            {
                                type: "file",
                                icon: "fa-brands fa-square-js",
                                name: `${rootFolderName}-admin.js`,
                                data: files.pluginNameAdminJs,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-square-js",
                                name: `${rootFolderName}-frontend.js`,
                                data: files.pluginNameFrontendJs,
                            },
                            ...dynamicJs,
                        ],
                        folders: [],
                    },
                ],
            },
            {
                type: "folder",
                folderName: "dev",
                path: "dev",
                isExpand: true,
                files: [],
                folders: [
                    {
                        type: "folder",
                        folderName: "scss",
                        path: "dev/scss",
                        isExpand: true,
                        files: [
                            {
                                type: "file",
                                icon: "fa-brands fa-sass",
                                name: "survey.scss",
                                data: files.surveyScss,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-sass",
                                name: "sdk.scss",
                                data: files.SDKScss,
                            },
                            ...dynamicScss,
                        ],
                        folders: [
                            {
                                type: "folder",
                                folderName: "admin",
                                path: "dev/scss/admin",
                                isExpand: true,
                                files: [
                                    {
                                        type: "file",
                                        icon: "fa-brands fa-sass",
                                        name: "dashboard.scss",
                                        data: files.dashboardScss,
                                    },
                                ],
                                folders: [],
                            },
                            {
                                type: "folder",
                                folderName: "common",
                                path: "dev/scss/common",
                                isExpand: true,
                                files: [
                                    {
                                        type: "file",
                                        icon: "fa-brands fa-sass",
                                        name: "variables.scss",
                                        data: files.variableScss,
                                    },
                                    {
                                        type: "file",
                                        icon: "fa-brands fa-sass",
                                        name: "mixin.scss",
                                        data: files.mixinScss,
                                    },
                                ],
                                folders: [],
                            },
                        ],
                    },
                    {
                        type: "folder",
                        folderName: "js",
                        path: "dev/js",
                        isExpand: true,
                        files: [...dynamicJsHandle],
                        folders: [],
                    },
                ],
            },
            {
                type: "folder",
                folderName: "Inc",
                path: "Inc",
                isExpand: true,
                files: [
                    {
                        type: "file",
                        icon: "fa-brands fa-php",
                        name: "functions.php",
                        data: files.functionPhp,
                    },
                ],
                folders: [
                    {
                        type: "folder",
                        folderName: "Admin",
                        path: "Inc/Admin",
                        isDownload: isAdminSettings ? "yes" : "no",
                        isExpand: true,
                        files: [
                            {
                                type: "file",
                                icon: "fa-brands fa-php",
                                name: "AdminSettings.php",
                                data: files.adminSettingsPhp,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-php",
                                name: "Settings_API.php",
                                data: files.settinsAPIPhp,
                            },
                        ],
                        folders: [],
                    },
                    {
                        type: "folder",
                        folderName: "Classes",
                        path: "Inc/Classes",
                        isExpand: true,
                        files: [
                            {
                                type: "file",
                                icon: "fa-brands fa-php",
                                name: "Recommended_Plugins.php",
                                data: files.recommendedPluginsPhp,
                                isDownload: pluginType === "adminify_module" ? "no" : "",
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-php",
                                name: "Row_Links.php",
                                data: files.incRowLinkPhp,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-php",
                                name: "Upgrade_Plugin.php",
                                data: files.upgradePluginPhp,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-php",
                                name: "Pro_Upgrade.php",
                                data: files.proUpgradePhp,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-php",
                                name: "Feedback.php",
                                data: files.feedbackPhp,
                            },
                        ],
                        folders: [
                            {
                                type: "folder",
                                folderName: "Notifications",
                                path: "Inc/Classes/Notifications",
                                isExpand: true,
                                files: [
                                    {
                                        type: "file",
                                        icon: "fa-brands fa-php",
                                        name: "Ask_For_Rating.php",
                                        data: files.askForRatingPhp,
                                    },
                                    {
                                        type: "file",
                                        icon: "fa-brands fa-php",
                                        name: "Manager.php",
                                        data: files.managerPhp,
                                    },
                                    {
                                        type: "file",
                                        icon: "fa-brands fa-php",
                                        name: "Notifications.php",
                                        data: files.notificationsPhp,
                                    },
                                    {
                                        type: "file",
                                        icon: "fa-brands fa-php",
                                        name: "Upgrade_Notice.php",
                                        data: files.upgradeNoticePhp,
                                    },
                                    {
                                        type: "file",
                                        icon: "fa-brands fa-php",
                                        name: "Subscribe.php",
                                        data: files.incSubscribePhp,
                                    },
                                    {
                                        type: "file",
                                        icon: "fa-brands fa-php",
                                        name: "What_We_Collect.php",
                                        data: files.whatWeCollectPhp,
                                    },
                                ],
                                folders: [
                                    {
                                        type: "folder",
                                        folderName: "Base",
                                        path: "Inc/Classes/Notifications/Base",
                                        isExpand: true,
                                        files: [
                                            {
                                                type: "file",
                                                icon: "fa-brands fa-php",
                                                name: "Data.php",
                                                data: files.dataPhp,
                                            },
                                            {
                                                type: "file",
                                                icon: "fa-brands fa-php",
                                                name: "Date.php",
                                                data: files.datePhp,
                                            },
                                            {
                                                type: "file",
                                                icon: "fa-brands fa-php",
                                                name: "User_Data.php",
                                                data: files.userDataPhp,
                                            },
                                        ],
                                        folders: [],
                                    },
                                    {
                                        type: "folder",
                                        folderName: "Model",
                                        path: "Inc/Classes/Notifications/Model",
                                        isExpand: true,
                                        files: [
                                            {
                                                type: "file",
                                                icon: "fa-brands fa-php",
                                                name: "Notice.php",
                                                data: files.noticePhp,
                                            },
                                            {
                                                type: "file",
                                                icon: "fa-brands fa-php",
                                                name: "Notification.php",
                                                data: files.NotificationPhp,
                                            },
                                            {
                                                type: "file",
                                                icon: "fa-brands fa-php",
                                                name: "Popup.php",
                                                data: files.popupPhp,
                                            },
                                        ],
                                        folders: [],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        type: "folder",
                        folderName: "Upgrades",
                        path: "Inc/Upgrades",
                        isExpand: true,
                        files: [
                            {
                                type: "file",
                                icon: "fa-brands fa-php",
                                name: `upgrade-${
                                    pluginInfo.pluginVersion ? pluginInfo.pluginVersion : "1.0.2"
                                }.php`,
                                data: files.upgradeVersion,
                            },
                        ],
                        folders: [],
                    },
                ],
            },
            {
                type: "folder",
                folderName: "Libs",
                path: "Libs",
                isExpand: true,
                files: [
                    {
                        type: "file",
                        icon: "fa-brands fa-php",
                        name: "Assets.php",
                        data: files.assetsPhp,
                    },
                    {
                        type: "file",
                        icon: "fa-brands fa-php",
                        name: "Helper.php",
                        data: files.helperPhp,
                    },
                    {
                        type: "file",
                        icon: "fa-brands fa-php",
                        name: "Recommended.php",
                        data: files.recommendedPhp,
                        isDownload: pluginType === "adminify_module" ? "no" : "",
                    },
                    {
                        type: "file",
                        icon: "fa-brands fa-php",
                        name: "RowLinks.php",
                        data: files.rowLinkPhp,
                    },
                    {
                        type: "file",
                        icon: "fa-brands fa-php",
                        name: "Upgrader.php",
                        data: files.upgraderPhp,
                    },
                    {
                        type: "file",
                        icon: "fa-brands fa-php",
                        name: "Featured.php",
                        data: files.featuredPhp,
                    },
                    {
                        type: "file",
                        icon: "fa-brands fa-php",
                        name: "Update_Checker.php",
                        data: files.updateCheckerPhp,
                        isDownload: pluginType === "adminify_module" ? "yes" : "no",
                    },
                ],
                folders: [],
            },
            {
                type: "folder",
                folderName: "Pro",
                path: "Pro",
                isExpand: true,
                isDownload: pluginType === "free_pro_bundle" ? "yes" : "no",
                files: [],
                folders: [
                    {
                        type: "folder",
                        folderName: "License",
                        path: "Pro/License",
                        isExpand: true,
                        files: [
                            {
                                type: "file",
                                icon: "fa-brands fa-php",
                                name: "Loader.php",
                                data: files.loaderPhp,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-php",
                                name: "examples.php",
                                data: files.examplesPhp,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-php",
                                name: "Client.php",
                                data: files.clientPhp,
                            },
                            {
                                type: "file",
                                icon: "fa-brands fa-php",
                                name: "manager.php",
                                data: files.libManagerPhp,
                            },
                        ],
                        folders: [
                            {
                                type: "folder",
                                folderName: "assets",
                                path: "Pro/License/assets",
                                isExpand: true,
                                files: [
                                    {
                                        type: "file",
                                        icon: "fa-brands fa-css3-alt",
                                        name: "license.css",
                                        data: files.licenseCss,
                                    },
                                    {
                                        type: "file",
                                        icon: "fa-brands fa-square-js",
                                        name: "license.js",
                                        data: files.licenseJs,
                                    },
                                ],
                                folders: [],
                            },
                        ],
                    },
                    {
                        type: "folder",
                        folderName: "Classes",
                        path: "Pro/Classes",
                        isExpand: true,
                        files: [
                            {
                                type: "file",
                                icon: "fa-brands fa-php",
                                name: "Filters.php",
                                data: files.filtersPhp,
                            },
                        ],
                        folders: [],
                    },
                ],
            },
            {
                type: "file",
                icon: "fa-brands fa-php",
                name: `${rootFolderName}.php`,
                data: files.pixarlabsSDKphp,
            },
            {
                type: "file",
                icon: "fa-brands fa-php",
                name: `class-${rootFolderName}.php`,
                data: files.classPixarlabsPhp,
            },
            {
                type: "file",
                icon: "fa-brands fa-git-alt",
                name: "composer.json",
                data: files.composerJson,
            },
            {
                type: "file",
                icon: "fa-brands fa-git-alt",
                name: ".gitignore",
                data: files.gitIgnore,
            },
            {
                type: "file",
                icon: "fa-brands fa-git-alt",
                name: "phpcs.xml.dist",
                data: files.phpcsXmlDist,
            },
            {
                type: "file",
                icon: "fa-brands fa-git-alt",
                name: ".distignore",
                data: files.disIgnore,
            },
            {
                type: "file",
                icon: "fa-brands fa-git-alt",
                name: "readme.txt",
                data: files.readmeTxt,
            },
            {
                type: "file",
                icon: "fa-brands fa-git-alt",
                name: ".gitattributes",
                data: files.gitAttributes,
            },
            {
                type: "file",
                icon: "fa-brands fa-git-alt",
                name: ".php-cs-fixer.dist.php",
                data: files.phpCsFixerDist,
            },
            {
                type: "file",
                icon: "fa-brands fa-git-alt",
                name: "package.json",
                data: files.packageJson,
            },
            {
                type: "file",
                icon: "fa-brands fa-git-alt",
                name: ".babelrc",
                data: files.babelRc,
            },
            {
                type: "file",
                icon: "fa-brands fa-git-alt",
                name: ".stylelintrc.json",
                data: files.styleLintRc,
            },
            {
                type: "file",
                icon: "fa-brands fa-git-alt",
                name: ".eslintignore",
                data: files.esLintIgnore,
            },
            {
                type: "file",
                icon: "fa-brands fa-git-alt",
                name: ".eslintrc.json",
                data: files.esLintRcJson,
            },
            {
                type: "file",
                icon: "fa-brands fa-git-alt",
                name: "webpack.mix.js",
                data: files.webpackMixJs,
            },
            {
                type: "file",
                icon: "fa-brands fa-php",
                name: "loader.php",
                data: files.rootLoaderPhp,
                isDownload: pluginType === "adminify_module" ? "yes" : "no",
            },
            // PRO Files
            {
                type: "file",
                icon: "fa-brands fa-php",
                name: "autoloader-pro.php",
                data: files.autoloaderProPhp,
            },
            {
                type: "file",
                icon: "fa-brands fa-php",
                name: `class-${rootFolderName}-pro.php`,
                data: files.classPixarlabsProPhp,
            },
            {
                type: "file",
                icon: "fa-brands fa-php",
                name: "classmaps-pro.php",
                data: files.classmapProPhp,
            },
        ];

        setFileStructures(mainData);
    }, [
        files,
        addCssItem,
        addJsItem,
        pluginInfo.pluginVersion,
        rootFolderName,
        isAdminSettings,
        pluginType,
    ]);

    return (
        <Fragment>
            {pluginInfo.pluginName ? (
                <div>
                    <ul className="wpg_view__tree">
                        <li>
                            <ViewTree
                                toggle={pluginNameToggole}
                                setToggle={setPluginNameToggole}
                                folderName={rootFolderName}
                                isExpand={true}
                            />
                            {pluginNameToggole ? <FileRenderer files={fileStructures} /> : null}
                        </li>
                    </ul>
                    <button
                        onClick={() => {
                            if (!pluginType) {
                                return alert("Please select a plugin type!");
                            }

                            getDownload(fileStructures, rootFolderName);
                        }}
                        className="mt-4 bg-[#007bff] text-white text-[1rem] w-full py-[6px] px-[12px] rounded-[3px]"
                    >
                        Download
                    </button>
                </div>
            ) : (
                <Fragment>
                    <p className="text-[#24263a] text-[1rem] font-normal leading-[1.5rem] text-center italic">
                        No Preview Available
                    </p>
                </Fragment>
            )}
        </Fragment>
    );
};

export default GeneratedFiles;
