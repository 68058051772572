import { useEffect } from "react";
import { useGlobalPluginInfo } from "../../context/pluginInfoContext";
import { kebabCase } from "lodash";
import { getPluginFolderName } from "../../utils/getPluginFolderName";
import { useFileStore } from "../../strore/useFileStore";

const AssetsPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.assetsPhp);
    const { pluginInfo, advancedItem, pluginType } = useGlobalPluginInfo();
    const {
        pluginName,
        pluginTextDomain,
        pluginVersion,
        pluginClassPrefix,
        pluginAuthor,
        pluginAuthorEmail,
    } = pluginInfo;
    const { constantPrefix, functionPrefix, baseNamespace, mainClassName } = advancedItem;

    const rootFolderName = getPluginFolderName(pluginName);

    useEffect(() => {
        let value = `<?php
		${
            pluginType === "adminify_module"
                ? `namespace WPAdminify\\Modules\\${baseNamespace}\\Libs;`
                : `namespace ${baseNamespace}\\Libs;`
        }

// No, Direct access Sir !!!
if ( ! defined( 'ABSPATH' ) ) {
	exit;
}

if ( ! class_exists( 'Assets' ) ) {

	/**
	 * Assets Class
	 *
	 * ${pluginAuthor} <${pluginAuthorEmail}>
	 * @version     ${pluginVersion}
	 */
	class Assets {

		/**
		 * Constructor method
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function __construct() {
			add_action( 'wp_enqueue_scripts', array( $this, '${functionPrefix}_enqueue_scripts' ), 100 );
			add_action( 'admin_enqueue_scripts', array( $this, '${functionPrefix}_admin_enqueue_scripts' ), 100 );
		}


		/**
		 * Get environment mode
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function get_mode() {
			return defined( 'WP_DEBUG' ) && WP_DEBUG ? 'development' : 'production';
		}

		/**
		 * Enqueue Scripts
		 *
		 * @method wp_enqueue_scripts()
		 */
		public function ${functionPrefix}_enqueue_scripts() {

			// CSS Files .
			wp_enqueue_style( '${rootFolderName}-frontend', ${constantPrefix}_ASSETS . 'css/${rootFolderName}-frontend.css', ${constantPrefix}_VER, 'all' );

			// JS Files .
			wp_enqueue_script( '${rootFolderName}-frontend', ${constantPrefix}_ASSETS . 'js/${kebabCase(
            pluginName
        )}-frontend.js', array( 'jquery' ), ${constantPrefix}_VER, true );
		}


		/**
		 * Enqueue Scripts
		 *
		 * @method admin_enqueue_scripts()
		 */
		public function ${functionPrefix}_admin_enqueue_scripts() {
			// CSS Files .
			wp_enqueue_style( '${rootFolderName}-admin', ${constantPrefix}_ASSETS . 'css/${kebabCase(
            pluginName
        )}-admin.css', array( 'dashicons' ), ${constantPrefix}_VER, 'all' );

			// JS Files .
			wp_enqueue_script( '${rootFolderName}-admin', ${constantPrefix}_ASSETS . 'js/${kebabCase(
            pluginName
        )}-admin.js', array( 'jquery' ), ${constantPrefix}_VER, true );
			wp_localize_script(
				'${rootFolderName}-admin',
				'${constantPrefix}CORE',
				array(
					'admin_ajax'        => admin_url( 'admin-ajax.php' ),
					'recommended_nonce' => wp_create_nonce( '${functionPrefix}_recommended_nonce' ),${
            pluginType === "free_pro_bundle"
                ? `
					'is_premium'        => ${functionPrefix}_is_premium(),
					'is_agency'         => ${functionPrefix}_is_plan( 'agency' ),`
                : ""
        }
				)
			);
		}
	}
}`;
        setStore({ assetsPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pluginName,
        pluginTextDomain,
        rootFolderName,
        pluginType,
        pluginVersion,
        pluginClassPrefix,
        mainClassName,
        pluginAuthorEmail,
        baseNamespace,
        constantPrefix,
        functionPrefix,
        pluginAuthor,
    ]);

    return null;
};

export default AssetsPhp;
