import { createContext, useContext, useState } from "react";

export const PluginInfoContext = createContext();

export const PluginInfoProvider = ({ children }) => {
    const [pluginType, setPluginType] = useState("");
    const [pluginInfo, setPluginInfo] = useState({
        pluginName: "",
        pluginUri: "",
        pluginDescription: "",
        pluginVersion: "1.0.0",
        pluginAuthor: "Jewel Theme",
        pluginAuthorUrl: "https://jeweltheme.com",
        pluginAuthorEmail: "support@jeweltheme.com",
        pluginLicense: "GPLv3 or later",
        pluginLicenseUrl: "https://www.gnu.org/licenses/gpl-3.0.html",
        pluginTextDomain: "",
        pluginDomainPath: "languages/",
        pluginClassPrefix: "",
        askForRating: "",
    });
    // Prefix Files
    const [advancedItem, setAdvancedItem] = useState({
        baseNamespace: "",
        mainClassName: "",
        constantPrefix: "",
        functionPrefix: "",
    });
    // Menu Files
    const [mainMenuItem, setmainMenuItem] = useState({
        isAdminSettings: false,
        menuTitle: "",
        pageTitle: "",
        menuCapability: "manage_options",
        menuPageSlug: "",
        menuIcon: "dashicons-admin-generic",
        menuPositon: 40,
    });

    //CRMSettings & License State
    const [crm, setCrm] = useState({
        listId: 3,
        tagId: `35, 40, `,
        siteSource: "https://jeweltheme.com",
        crmEndpoint: "https://bo.jeweltheme.com",
    });

    //sheet
    const [sheet, setSheet] = useState({
        sheetId: "1VLpfKspHHNM6JIFOQtohqDRyHR85J3KR5RLF4jqlz0Q",
        tabId: 0,
    });

    //Dash widget
    const [dashWidget, setDashWidget] = useState({
        feedUrl: "https://jeweltheme.com/feed.xml",
        maxItem: 5,
        infoLinks: [
            {
                label: "Blog",
                url: "https://jeweltheme.com/blog",
            },
            {
                label: "Help",
                url: "https://jeweltheme.com/docs",
            },
            {
                label: "Upgrade",
                url: "https://jeweltheme.com",
            },
        ],
    });

    //crm License
    const [license, setLicense] = useState({
        licenseEndpoint: "https://bo.jeweltheme.com",
        productId: "",
        redirectUrl: "",
        softwareType: "plugin",
        menu: {
            iconUrl: "dashicons-image-filter",
            position: 40,
            type: "add_submenu_page",
            parentSlug: "",
            menuTitle: "License",
            pageTitle: "License Activation",
        },
    });

    // CSS, Js Files & Table section state
    const [addCssItem, setAddCssItem] = useState([]);
    const [addJsItem, setAddJsItem] = useState([]);
    const [addTableItem, setAddTableItem] = useState([]);

    //row_link
    const [rowLinks, setRowLinks] = useState([]);

    //featured
    const [featured, setFeatured] = useState([
        {
            slug: "ultimate-blocks-for-gutenberg",
        },
        {
            slug: "adminify",
        },
        {
            slug: "master-addons",
        },
    ]);

    //recommended plugins
    const [rcdPlugin, setRcdPlugin] = useState({
        allPlugin: [
            {
                icon_url: "https://ps.w.org/adminify/assets/icon.svg",
                name: "WP Adminify – Custom Login, Admin Dashboard, Admin Columns | White Label | Media Library Folders",
                slug: "adminify",
                short_description:
                    "WP Adminify enhances the WordPress Dashboard Customization journey. It comes with 20+ modules, such as Media Folder, Login Customizer, Menu Editor, Admin Columns, Activity Logs, Disable Admin Notice, WordPress White Label, Admin Page, Google Pagespeed Insights, Custom CSS/JS, and many more. It can be your best choice because of its Multiple Dashboard UI Templates and lightweight size (4MB).",
                type: [
                    { label: "All", value: "all" },
                    { label: "Featured", value: "featured" },
                    { label: "Popular", value: "popular" },
                    { label: "Favorites", value: "favorites" },
                ],
            },
            {
                icon_url: "https://ps.w.org/ultimate-blocks-for-gutenberg/assets/icon-256x256.png",
                name: "Master Blocks – Gutenberg Blocks Page Builder",
                slug: "ultimate-blocks-for-gutenberg",
                short_description:
                    "Gutenberg is now a reality. Easy Blocks is a growing Gutenberg Block Plugin. It’s easy to create multiple columns variations within a single row. Drag and Drop columns size variations gives you more controls over your website. We’re calling it as a Gutenberg Page Builder. If you are using Gutenberg Editor, then you can try this plugin to get maximum customization possibilities.",
                type: [
                    { label: "All", value: "all" },
                    { label: "Featured", value: "featured" },
                    { label: "Favorites", value: "favorites" },
                ],
            },
            {
                icon_url: "https://ps.w.org/master-addons/assets/icon.svg",
                name: "Master Addons for Elementor",
                slug: "master-addons",
                short_description:
                    "Master Addons for Elementor provides the most comprehensive Elements & Extensions with a user-friendly interface. It is packed with 50+ Elementor Elements & 20+ Extension.",
                type: [
                    { label: "All", value: "all" },
                    { label: "Featured", value: "featured" },
                    { label: "Popular", value: "popular" },
                    { label: "Favorites", value: "favorites" },
                ],
            },
            {
                icon_url:
                    "https://ps.w.org/image-comparison-elementor-addon/assets/icon-256x256.png",
                name: "Image Comparison Elementor Addon",
                slug: "image-comparison-elementor-addon",
                short_description:
                    "Image Comparison Elementor Addon is a Plugin that gives you the control to add before and after image. You will get the full control to customize everything you need for image or photo comparison.",
                type: [{ label: "All", value: "all" }],
            },
            {
                icon_url: "https://ps.w.org/wp-awesome-faq/assets/icon-128x128.png",
                name: "Master Accordion ( Former WP Awesome FAQ Plugin )",
                slug: "wp-awesome-faq",
                short_description:
                    "No need extra configurations for WP Awesome FAQ Plugin. WP Awesome FAQ Plugin allows to create unlimited FAQ Items with Title, Description. With the plugin installation’s a Custom Post Type named “FAQ” will be created automatically. To show all FAQ’s items a shortcode [faq] needed.",
                type: [
                    { label: "All", value: "all" },
                    { label: "Featured", value: "featured" },
                    { label: "Popular", value: "popular" },
                ],
            },
            {
                icon_url: "https://ps.w.org/prettyphoto/assets/icon-256x256.png",
                name: "WordPress prettyPhoto",
                slug: "prettyphoto",
                short_description:
                    "Master Addons is Collection of Exclusive & Unique Addons for Elementor Page Builder. This Plugin that gives you full control over Images to show in your website.",
                type: [
                    { label: "All", value: "all" },
                    { label: "Featured", value: "featured" },
                    { label: "Popular", value: "popular" },
                    { label: "Favorites", value: "favorites" },
                ],
            },
        ],
        menuTitle: "Recommended",
        pageTitle: "Recommended",
        pluginPosition: "submenu",
        submenuSlug: "",
        menuOrder: 15,
        menuIcon: "dashicons-randomize",
    });

    //readme
    const [readmeData, setReadmeData] = useState({
        pluginName: "",
        contributors: "litonice13, pixarlabs, jwthemeltd",
        donateLink: "https://www.buymeacoffee.com/jeweltheme",
        tags: "",
        stableTag: "trunk",
        requiresPHP: "7.0",
        requiresAtLeast: "5.0",
        testedUpTo: "6.7.1",
        // license: "",
        // licenseURI: "",
        shortDesc: "",
        desc: "",
        faq: [],
        screenshots: [],
        installInstruction: "",
        upgradeNotice: "",
    });

    return (
        <PluginInfoContext.Provider
            value={{
                pluginType,
                setPluginType,
                pluginInfo,
                setPluginInfo,
                advancedItem,
                setAdvancedItem,
                mainMenuItem,
                setmainMenuItem,
                addCssItem,
                setAddCssItem,
                addJsItem,
                setAddJsItem,
                addTableItem,
                setAddTableItem,
                rowLinks,
                setRowLinks,
                rcdPlugin,
                setRcdPlugin,
                crm,
                setCrm,
                sheet,
                setSheet,
                dashWidget,
                setDashWidget,
                license,
                setLicense,
                featured,
                setFeatured,
                readmeData,
                setReadmeData,
            }}
        >
            {children}
        </PluginInfoContext.Provider>
    );
};

export const useGlobalPluginInfo = () => {
    return useContext(PluginInfoContext);
};
