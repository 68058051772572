import { useEffect } from "react";
import { useGlobalPluginInfo } from "../context/pluginInfoContext";
import { getPluginFolderName } from "../utils/getPluginFolderName";
import { isEmpty } from "lodash";
import { useFileStore } from "../strore/useFileStore";

const ClassPixarlabsPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.classPixarlabsPhp);
    const { pluginInfo, advancedItem, pluginType, mainMenuItem, rowLinks } = useGlobalPluginInfo();

    const { constantPrefix, functionPrefix, mainClassName, baseNamespace } = advancedItem;
    const {
        pluginVersion,
        pluginAuthor,
        pluginAuthorEmail,
        pluginTextDomain,
        pluginName,
        pluginClassPrefix,
    } = pluginInfo;
    const { isAdminSettings } = mainMenuItem;

    const rootFolderName = getPluginFolderName(pluginName);

    useEffect(() => {
        let value = `${
            pluginType === "adminify_module"
                ? `<?php
namespace WPAdminify\\Modules\\${baseNamespace};

use WPAdminify\\Modules\\${baseNamespace}\\Libs\\Helper;
use WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Notifications\\Notifications;
use WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Pro_Upgrade;
use WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Upgrade_Plugin;
use WPAdminify\\Modules\\${baseNamespace}\\Inc\\Classes\\Feedback;

/**
 * Main Class
 *
 * @${pluginInfo?.pluginName}
 * ${pluginAuthor} <${pluginAuthorEmail}>
 * @version     ${pluginVersion}
 */

// No, Direct access Sir !!!
if ( ! defined( 'ABSPATH' ) ) {
	exit;
}

/**
 * Class: ${mainClassName}
 */
final class ${mainClassName} {

	const VERSION            = ${constantPrefix}_VER;
	private static $instance = null;
	public $url;

	/**
	 * what we collect construct method
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function __construct() {
		add_action( 'plugins_loaded', array( $this, '${functionPrefix}_plugins_loaded' ) );

		// Body Class.
		add_filter( 'admin_body_class', array( $this, '${functionPrefix}_body_class' ) );
	}

	/**
	 * plugins_loaded method
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function ${functionPrefix}_plugins_loaded() {
		new Pro_Upgrade();
		new Notifications();
		new Feedback();
	}

	/**
	 * Version Key
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public static function plugin_version_key() {
		return Helper::${functionPrefix}_slug_cleanup() . '_version';
	}

	/**
	 * Activation Hook
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public static function ${functionPrefix}_activate() {
		$current_${functionPrefix}_version = get_option( self::plugin_version_key(), null );

		if ( get_option( '${functionPrefix}_activation_time' ) === false ) {
			update_option( '${functionPrefix}_activation_time', strtotime( 'now' ) );
		}

		if ( is_null( $current_${functionPrefix}_version ) ) {
			update_option( self::plugin_version_key(), self::VERSION );
		}

		$allowed = get_option( Helper::${functionPrefix}_slug_cleanup() . '_allow_tracking', 'no' );

		// if it wasn't allowed before, do nothing .
		if ( 'yes' !== $allowed ) {
			return;
		}
		// re-schedule and delete the last sent time so we could force send again .
		$hook_name = Helper::${functionPrefix}_slug_cleanup() . '_tracker_send_event';
		if ( ! wp_next_scheduled( $hook_name ) ) {
			wp_schedule_event( time(), 'weekly', $hook_name );
		}
	}


	/**
	 * Add Body Class
	 *
	 * @param [type] $classes .
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function ${functionPrefix}_body_class( $classes ) {
		$classes .= ' ${rootFolderName} ';
		return $classes;
	}

	/**
	 * Initialization
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function ${functionPrefix}_init() {
		$this->${functionPrefix}_load_textdomain();
	}

	/**
	 * Text Domain
	 *
	 * @author ${pluginAuthor} <${pluginAuthorEmail}>
	 */
	public function ${functionPrefix}_load_textdomain() {		
		add_action('init', function () {
			$domain = '${pluginTextDomain}';
			$$locale = apply_filters( '${functionPrefix}_plugin_locale', get_locale(), $domain );

			load_textdomain($domain, WP_LANG_DIR . '/' . $domain . '/' . $domain . '-' . $locale . '.mo');
			load_plugin_textdomain($domain, false, dirname(${constantPrefix}_BASE) . '/languages/');
		});
	}

	/**
	 * Returns the singleton instance of the class.
	 */
	public static function get_instance() {
		if ( ! isset( self::$instance ) && ! ( self::$instance instanceof ${mainClassName} ) ) {
			self::$instance = new ${mainClassName}();
			self::$instance->${functionPrefix}_init();
		}

		return self::$instance;
	}
}

${mainClassName}::get_instance();`
                : `<?php
namespace ${baseNamespace};

use ${baseNamespace}\\Libs\\Assets;
use ${baseNamespace}\\Libs\\Helper;
use ${baseNamespace}\\Libs\\Featured;
use ${baseNamespace}\\Inc\\Classes\\Recommended_Plugins;
use ${baseNamespace}\\Inc\\Classes\\Notifications\\Notifications;
use ${baseNamespace}\\Inc\\Classes\\Pro_Upgrade;${
                      !isEmpty(rowLinks)
                          ? `
use ${baseNamespace}\\Inc\\Classes\\Row_Links;`
                          : ""
                  }
use ${baseNamespace}\\Inc\\Classes\\Upgrade_Plugin;${
                      isAdminSettings
                          ? `
use ${baseNamespace}\\Inc\\Admin\\AdminSettings;`
                          : ""
                  }
use ${baseNamespace}\\Inc\\Classes\\Feedback;

/**
 * Main Class
 *
 * @${pluginInfo?.pluginName}
 * ${pluginAuthor} <${pluginAuthorEmail}>
 * @version     ${pluginVersion}
 */

// No, Direct access Sir !!!
if ( ! defined( 'ABSPATH' ) ) {
	exit;
}

/**
 * ${mainClassName} Class
 */
if ( ! class_exists( '\\${baseNamespace}\\${mainClassName}' ) ) {

	/**
	 * Class: ${mainClassName}
	 */
	final class ${mainClassName} {

		const VERSION            = ${constantPrefix}_VER;
		private static $instance = null;

		/**
		 * what we collect construct method
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function __construct() {
			$this->includes();
			add_action( 'plugins_loaded', array( $this, '${functionPrefix}_plugins_loaded' ), 999 );
			// Body Class.
			add_filter( 'admin_body_class', array( $this, '${functionPrefix}_body_class' ) );
			// This should run earlier .
			// add_action( 'plugins_loaded', [ $this, '${functionPrefix}_maybe_run_upgrades' ], -100 ); .
		}

		/**
		 * plugins_loaded method
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function ${functionPrefix}_plugins_loaded() {
			$this->${functionPrefix}_activate();
		}

		/**
		 * Version Key
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public static function plugin_version_key() {
			return Helper::${functionPrefix}_slug_cleanup() . '_version';
		}

		/**
		 * Activation Hook
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public static function ${functionPrefix}_activate() {
			$current_${functionPrefix}_version = get_option( self::plugin_version_key(), null );

			if ( get_option( '${functionPrefix}_activation_time' ) === false ) {
				update_option( '${functionPrefix}_activation_time', strtotime( 'now' ) );
			}

			if ( is_null( $current_${functionPrefix}_version ) ) {
				update_option( self::plugin_version_key(), self::VERSION );
			}

			$allowed = get_option( Helper::${functionPrefix}_slug_cleanup() . '_allow_tracking', 'no' );

			// if it wasn't allowed before, do nothing .
			if ( 'yes' !== $allowed ) {
				return;
			}
			// re-schedule and delete the last sent time so we could force send again .
			$hook_name = Helper::${functionPrefix}_slug_cleanup() . '_tracker_send_event';
			if ( ! wp_next_scheduled( $hook_name ) ) {
				wp_schedule_event( time(), 'weekly', $hook_name );
			}
		}


		/**
		 * Add Body Class
		 *
		 * @param [type] $classes .
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function ${functionPrefix}_body_class( $classes ) {
			$classes .= ' ${pluginTextDomain} ';
			return $classes;
		}

		/**
		 * Run Upgrader Class
		 *
		 * @return void
		 */
		public function ${functionPrefix}_maybe_run_upgrades() {
			if ( ! is_admin() && ! current_user_can( 'manage_options' ) ) {
				return;
			}

			// Run Upgrader .
			$upgrade = new Upgrade_Plugin();

			// Need to work on Upgrade Class .
			if ( $upgrade->if_updates_available() ) {
				$upgrade->run_updates();
			}
		}

		/**
		 * Include methods
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function includes() {${
            isAdminSettings
                ? `
			new AdminSettings();`
                : ""
        }
			new Assets();
			new Recommended_Plugins();${
                !isEmpty(rowLinks)
                    ? `
			new Row_Links();`
                    : ""
            }
			new Pro_Upgrade();
			new Notifications();
			new Featured();
			new Feedback();
		}


		/**
		 * Initialization
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function ${functionPrefix}_init() {
			$this->${functionPrefix}_load_textdomain();
		}


		/**
		 * Text Domain
		 *
		 * @author ${pluginAuthor} <${pluginAuthorEmail}>
		 */
		public function ${functionPrefix}_load_textdomain() {
			$domain = '${pluginTextDomain}';
			$locale = apply_filters( '${functionPrefix}_plugin_locale', get_locale(), $domain );

			load_textdomain( $domain, WP_LANG_DIR . '/' . $domain . '/' . $domain . '-' . $locale . '.mo' );
			load_plugin_textdomain( $domain, false, dirname( ${constantPrefix}_BASE ) . '/languages/' );
		}
		
		${
            pluginType === "free_pro_bundle"
                ? `/**
		* Deactivate Pro Plugin if it's not already active
		* 
		* @author ${pluginAuthor} <${pluginAuthorEmail}>
		*/
		public static function ${functionPrefix}_activation_hook() {
			if ( ${functionPrefix}_license_client()->is_free_plan() ) {
				$plugin = '${rootFolderName}-pro/${rootFolderName}.php';
			} else {
				$plugin = '${rootFolderName}/${rootFolderName}.php';
			}
			if ( is_plugin_active( $plugin ) ) {
				deactivate_plugins( $plugin );
			}
		}`
                : ""
        }
		

		/**
		 * Returns the singleton instance of the class.
		 */
		public static function get_instance() {
			if ( ! isset( self::$instance ) && ! ( self::$instance instanceof ${mainClassName} ) ) {
				self::$instance = new ${mainClassName}();
				self::$instance->${functionPrefix}_init();
			}

			return self::$instance;
		}
	}

	// Get Instant of ${mainClassName} Class .
	${mainClassName}::get_instance();
}`
        }`;

        setStore({ classPixarlabsPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pluginInfo,
        constantPrefix,
        functionPrefix,
        pluginAuthor,
        pluginAuthorEmail,
        pluginVersion,
        mainClassName,
        pluginTextDomain,
        baseNamespace,
        rootFolderName,
        pluginType,
        isAdminSettings,
        rowLinks,
        pluginClassPrefix,
    ]);

    return null;
};

export default ClassPixarlabsPhp;
