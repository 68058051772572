export const files = {
    // root files
    pixarlabsSDKphp: "",
    classPixarlabsPhp: "",
    composerJson: "",
    phpcsXmlDist: "",
    disIgnore: "",
    gitIgnore: "",
    readmeTxt: "",
    phpCsFixerDist: "",
    gitAttributes: "",
    packageJson: "",
    babelRc: "",
    styleLintRc: "",
    esLintRcJson: "",
    esLintIgnore: "",
    webpackMixJs: "",
    rootLoaderPhp: "",
    classmapProPhp: "",
    classPixarlabsProPhp: "",
    autoloaderProPhp: "",
    //.vscode
    settingsJson: "",
    //.github > workflows
    buildReleaseZip: "",
    dependencyReview: "",
    phpCompatibility: "",
    phpcs: "",
    phpunit: "",
    pushDeploy: "",
    wordpressLatest: "",
    wpcs: "",
    //assets > css
    pluginNameAdminCss: "",
    pluginSurveyCss: "",
    pluginNameFrontendCss: "",
    //assets > js
    pluginNameAdminJs: "",
    pluginNameFrontendJs: "",
    // dev > scss
    surveyScss: "",
    SDKScss: "",
    // dev > scss > admin
    dashboardScss: "",
    // dev > scss > common
    variableScss: "",
    mixinScss: "",
    //inc
    functionPhp: "",
    //inc > Admin folder
    adminSettingsPhp: "",
    settinsAPIPhp: "",
    // inc > Classes folder
    recommendedPluginsPhp: "",
    incRowLinkPhp: "",
    upgradePluginPhp: "",
    upgradeVersion: "",
    proUpgradePhp: "",
    feedbackPhp: "",
    //Inc > Classes > Notifications
    askForRatingPhp: "",
    managerPhp: "",
    notificationsPhp: "",
    upgradeNoticePhp: "",
    incSubscribePhp: "",
    whatWeCollectPhp: "",
    //Inc > Classes > Notifications > Base
    dataPhp: "",
    datePhp: "",
    userDataPhp: "",
    //Inc > Classes > Notifications > Model
    noticePhp: "",
    NotificationPhp: "",
    popupPhp: "",
    //libs folder
    assetsPhp: "",
    helperPhp: "",
    recommendedPhp: "",
    rowLinkPhp: "",
    upgraderPhp: "",
    featuredPhp: "",
    updateCheckerPhp: "",
    //Pro > Classes
    filtersPhp: "",
    //Pro > License
    clientPhp: "",
    loaderPhp: "",
    examplesPhp: "",
    libManagerPhp: "",
    //Pro > License > assets
    licenseCss: "",
    licenseJs: "",
};
