import { useEffect } from "react";
import { useGlobalPluginInfo } from "../context/pluginInfoContext";
import { getPluginFolderName } from "../utils/getPluginFolderName";
import { useFileStore } from "../strore/useFileStore";

const ComposerJSON = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.composerJson);
    const { pluginInfo, advancedItem, pluginType } = useGlobalPluginInfo();
    const { baseNamespace } = advancedItem;

    const rootFolderName = getPluginFolderName(pluginInfo.pluginName);

    useEffect(() => {
        let value = `{
    "name": "litonarefin/${rootFolderName}",
    "autoload": {
        "psr-4": {
            ${
                pluginType === "adminify_module"
                    ? `"WPAdminify\\\\Modules\\\\${baseNamespace}\\\\Inc\\\\": "Inc/",
            "WPAdminify\\\\Modules\\\\${baseNamespace}\\\\Inc\\\\Admin\\\\": "Inc/Admin",
            "WPAdminify\\\\Modules\\\\${baseNamespace}\\\\Libs\\\\": "Libs/",
            "WPAdminify\\\\Modules\\\\${baseNamespace}\\\\Libs\\\\License\\\\": "Libs/License/"`
                    : `"${baseNamespace}\\\\Inc\\\\": "Inc/",
            "${baseNamespace}\\\\Inc\\\\Admin\\\\": "Inc/Admin",
            "${baseNamespace}\\\\Libs\\\\": "Libs/"`
            }
        },
        "files": [
            "Inc/functions.php"
        ]
    },
    "authors": [
        {
            "name": "${pluginInfo?.pluginAuthor}",
            "email": "${pluginInfo?.pluginAuthorEmail}"
        }
    ],
    "require": {},
    "require-dev": {}
}`;
        setStore({ composerJson: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pluginType, baseNamespace, rootFolderName, pluginInfo]);

    return null;
};

export default ComposerJSON;
