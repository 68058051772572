import { useEffect } from "react";
import { useGlobalPluginInfo } from "../context/pluginInfoContext";
import { getPluginFolderName } from "../utils/getPluginFolderName";
import { useFileStore } from "../strore/useFileStore";

const ClassPixarlabsProPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.classPixarlabsProPhp);
    const { pluginInfo, advancedItem } = useGlobalPluginInfo();
    const rootFolderName = getPluginFolderName(pluginInfo?.pluginName);

    const { constantPrefix, functionPrefix, mainClassName, baseNamespace } = advancedItem;
    const { pluginName, pluginVersion, pluginAuthor, pluginTextDomain, pluginAuthorEmail } =
        pluginInfo;

    useEffect(() => {
        let value = `<?php

namespace ${baseNamespace}\\Pro;

/**
 * Main Class
 *
 * @${pluginName}
 * ${pluginAuthor} <${pluginAuthorEmail}>
 * @version     ${pluginVersion}
 */

// No, Direct access Sir !!!
if ( ! defined( 'ABSPATH' ) ) {
	exit;
}

/**
 * ${mainClassName} Class
 */
if ( ! class_exists('\\${baseNamespace}\\Pro\\${mainClassName}Pro' ) ) {

    class ${mainClassName}Pro
    {

        private static $instance = null;


        /**
         * construct method
         *
         * @author ${pluginAuthor} <${pluginAuthorEmail}>
         */

        public function __construct()
        {
            if (!$this->${functionPrefix}_installed() || !$this->${functionPrefix}_activated()) {
                add_action('admin_notices', array($this, '${functionPrefix}_notice_missing_main_plugin'));
            } else {
                add_action('plugins_loaded', array($this, '${functionPrefix}_dependencies'), -999999);
                add_action('plugins_loaded', array($this, '${functionPrefix}_includes'), 0);
            }
        }

        /**
         * Dependencies
         *
         * @return void
         */
        public function ${functionPrefix}_dependencies()
        {
            require_once ${constantPrefix}_PRO_DIR . 'autoloader-pro.php';
            require_once ${constantPrefix}_PRO_DIR . 'Pro/License/manager.php';
        }

        public function ${functionPrefix}_includes()
        {
            if (!$this->${functionPrefix}_activated()) {
                return;
            }
        }


        /**
         * Check is Plugin Active
         *
         * @param [type] $plugin_path
         *
         * @return boolean
         */
        public function ${functionPrefix}_activated($plugin_path = 'admin-bar/admin-bar.php')
        {
            include_once ABSPATH . 'wp-admin/includes/plugin.php';
            return is_plugin_active($plugin_path);
        }


        // Function to check if a plugin is installed
        public function ${functionPrefix}_installed($plugin_slug = 'admin-bar/admin-bar.php')
        {
            include_once ABSPATH . 'wp-admin/includes/plugin.php';
            $plugins = get_plugins();
            foreach ($plugins as $plugin_file => $plugin_data) {
                if (strpos($plugin_file, $plugin_slug) !== false) {
                    return true;
                }
            }
            return false;
        }



        /**
         * Install Required ${pluginName} Core Plugin
         */
        public function ${functionPrefix}_notice_missing_main_plugin()
        {

            $plugin = '${rootFolderName}/${rootFolderName}.php';
            if (!$this->${functionPrefix}_installed()) {

                if (!current_user_can('install_plugins')) {
                    return;
                }

                $install_activation_url = wp_nonce_url(self_admin_url('update.php?action=install-plugin&plugin=${rootFolderName}'), 'install-plugin_${rootFolderName}');
                $message = /* translators: 1: strong start tag, 2: strong end tag. */ sprintf(__('<b>${pluginName} Pro</b> requires %1$s"${pluginName}"%2$s plugin to be installed and activated. Please install ${pluginName} to continue.', '${pluginTextDomain}'), '<strong>', '</strong>');
                $button_text = __('Install ${pluginName}', '${pluginTextDomain}');
            } elseif (!$this->${functionPrefix}_activated($plugin)) {

                if (!current_user_can('activate_plugins')) {
                    return;
                }
                $install_activation_url = wp_nonce_url('plugins.php?action=activate&amp;plugin=' . $plugin . '&amp;plugin_status=all&amp;paged=1&amp;s', 'activate-plugin_' . $plugin);
                $message = __('<b>${pluginName} Pro</b> requires <strong>${pluginName}</strong> plugin to be active. Please activate ${pluginName} to continue.', '${pluginTextDomain}');
                $button_text = __('Activate ${pluginName}', '${pluginTextDomain}');
            }

            $button = '<p><a href="' . esc_url($install_activation_url) . '" class="button-primary">' . esc_html($button_text) . '</a></p>';

            printf('<div class="notice notice-warning is-dismissible"><p>%1$s</p>%2$s</div>', $message, $button);
        }



        public static function is_premium()
        {
            if (${functionPrefix}_license_client()->is_premium()) {
                return true;
            }
            return false;
        }
        public static function is_plan($plan_name)
        {
            if (${functionPrefix}_license_client()->is_plan($plan_name)) {
                return true;
            }
            return false;
        }


    	/**
		 * Returns the singleton instance of the class.
		 */
		public static function get_instance() {
			if ( ! isset( self::$instance ) && ! ( self::$instance instanceof ${mainClassName}Pro ) ) {
				self::$instance = new ${mainClassName}Pro();
			}

			return self::$instance;
		}
    }

    // Get Instant of ${mainClassName}Pro Class .
    ${mainClassName}Pro::get_instance();
}
`;
        setStore({ classPixarlabsProPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pluginName,
        pluginVersion,
        pluginAuthor,
        pluginTextDomain,
        pluginAuthorEmail,
        constantPrefix,
        functionPrefix,
        mainClassName,
        baseNamespace,
    ]);

    return null;
};

export default ClassPixarlabsProPhp;
