import { useEffect } from "react";
import { useGlobalPluginInfo } from "../context/pluginInfoContext";
import { useFileStore } from "../strore/useFileStore";

const ClassMapsProPhp = () => {
    // eslint-disable-next-line no-unused-vars
    const [_, setStore] = useFileStore((store) => store.classmapProPhp);
    const { advancedItem } = useGlobalPluginInfo();

    const { baseNamespace } = advancedItem;

    useEffect(() => {
        let value = `<?php

namespace ${baseNamespace}\\Pro;

// if direct access than exit the file
defined('ABSPATH') || exit;

return array(
    'Client'            => 'Pro/License/Client.php'
);
`;
        setStore({ classmapProPhp: value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseNamespace]);

    return null;
};

export default ClassMapsProPhp;
